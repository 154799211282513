<template>
  <CModal :show="show" v-on:update:show="close">
    <template slot="header">
      <CRow style="flex: 1; display:flex;" class="align-items-center justify-content-between flex-row flex-fill text-white">
        <CCol sm="10">
          <div class="font-lg">Импорт документа</div>
        </CCol>
        <CCol sm="2" class="justify-content-end" style="padding-right: 0 !important; display: flex;">
          <CButton v-on:click="close" size="lg" variant="ghost" color="primary" class="text-white">
            ×
          </CButton>
        </CCol>
      </CRow>
    </template>
    <template>
      <CForm @submit.prevent="startImport">
        <CInput
          label="Внешний URL документа"
          :value.sync="url"
        />
      </CForm>
    </template>
    <template #footer>
      <loading-button :loading="loading" @click="startImport" />
    </template>
  </CModal>
</template>

<script>
import LoadingButton from '@/components/loading-button';
import FormMixin from '@/mixins/form-mixins';

export default {
  name: 'ImportModal',
  mixins: [FormMixin],
  components: {
    LoadingButton,
  },
  data() {
    return {
      url: '',
      show: false,
      loading: false,
      errors: {}
    }
  },
  methods: {
    open() {
      this.show = true;
    },
    async startImport() {
      try {
        this.loading = true;
        const {data} = await this.$axios.post('api/imports/ms/internal_orders', {url: this.url});
        console.log(data);
        this.close();
        this.$router.push({name: 'admin.documents.info', params: {id: data.id}});
      } catch (e) {
        if (this.hasErrorsInResponse(e)) {
          this.errors = e.response.data.errors

          this.$nextTick(() => {
            this.showAllErrors();
          })
        }
      } finally {
        this.loading = false
      }
    },
    close() {
      this.show = false;
      this.url = '';
      this.loading = false;
      this.$emit('close');
    }
  }
}
</script>
