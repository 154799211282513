<template>
  <CCard body-wrapper>
    <import-modal ref="importModal" />
    <CRow align-vertical="center" class="mb-3">
     <CCol col="auto">
       <h2>
         {{ $t(`document.types.${documentType}`) }}
       </h2>
     </CCol>
     <CCol col="auto">
       <template v-if="isFiltersVisible">
         <template v-if="isFiltersSet">
           <CButton
             color="warning"
             @click="isFiltersVisible = !isFiltersVisible"
           >
             Фильтр
           </CButton>
         </template>
         <template v-else>
           <CButton
             color="dark"
             @click="isFiltersVisible = !isFiltersVisible"
           >
             Фильтр
           </CButton>
         </template>
       </template>
       <template v-else>
         <template v-if="isFiltersSet">
           <CButton
             color="warning"
             variant="outline"
             @click="isFiltersVisible = !isFiltersVisible"
           >
             Фильтр
           </CButton>
         </template>
         <template v-else>
           <CButton
             color="dark"
             variant="outline"
             @click="isFiltersVisible = !isFiltersVisible"
           >
             Фильтр
           </CButton>
         </template>
       </template>
     </CCol>
     <CCol col="auto">
       Документов: <span class="font-weight-bold">{{ allCount }}</span>
     </CCol>
     <CCol col="auto">
       Выбрано: <span class="font-weight-bold">{{ selected.length }}</span>
     </CCol>
     <CCol col="auto">
       <group-actions
        :actions="availableActions"
        :documents="selectedDocuments"
        @restart:end="restartSuccess"
      />
     </CCol>
     <CCol col="auto" v-if="canImportDocuments">
       <CButton color="primary" @click="$refs.importModal.open()">
         Импортировать документ
       </CButton>
     </CCol>
   </CRow>
   <CForm
     v-show="isFiltersVisible"
     class="filters mb-3 p-3"
     @submit.prevent="loadDocuments(1)"
   >
     <CRow align-vertical="center">
       <CCol
         xs="12"
         xl="4"
       >
         <div class="filters__filter">
           <CButton
             color="primary"
             style="width: 90px;"
             type="submit"
           >
             <template v-if="loading">
               <span
                 class="spinner-border spinner-border-sm"
                 role="status"
                 aria-hidden="true"
               />
               <span class="sr-only">Подождите...</span>
             </template>
             <template v-else>
               Найти
             </template>
           </CButton>
           <CButton
             class="ml-2"
             color="secondary"
             style="width: 90px;"
             @click="clearFilters"
           >
             Очистить
           </CButton>
         </div>
       </CCol>
       <CCol
         xs="12"
         xl="8"
       />
       <CCol
         xs="12"
         xl="4"
       >
         <div
           class="filters__filter"
           :class="{ 'filters__filter--active': filters.q }"
         >
           <CInput
             label="Номер"
             :value.sync="filters.q"
           />
         </div>
       </CCol>
       <CCol
         xs="12"
         xl="4"
       >
         <div
           class="filters__filter"
           :class="{ 'filters__filter--active': filters.quarantineReason }"
         >
           <CSelect
             :options="quarantineSelectValues"
             label="Тип карантина"
             :value.sync="filters.quarantineReason"
           />
         </div>
       </CCol>
     </CRow>
   </CForm>
   <CDataTable
     :items="listForTable"
     :fields="fields"
     hover
     striped
     v-on:page-change="changePage"
     :loading="loading"
    >
    <template #loading>
      <CElementCover
        :opacity="0.8"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
        />
      </CElementCover>
    </template>
    <template #no-items-view>
      <div class="text-center">
        Ничего не найдено
      </div>
    </template>

    <template #actions="{ item }">
      <td class="align-middle text-right">
        <CButton
          color="secondary"
          title="Подробнее"
          :to="{ name: 'admin.documents.info', params: { id: item.id } }"
        >
          <CIcon name="cilPencil" />
        </CButton>
      </td>
    </template>
    <template
      #[`groupActions-header`]="{}"
    >
      <div class="custom-control custom-checkbox">
        <CInputCheckbox
          id="all_items"
          :checked="allSelected"
          add-input-classes="custom-control-input"
          @update:checked="selectAll($event)"
        >
          <template #label>
            <label
              for="all_items"
              class="custom-control-label"
            />
          </template>
        </CInputCheckbox>
      </div>
    </template>
    <template #[`groupActions`]="{ item }">
      <td class="align-middle">
        <div class="custom-control custom-checkbox">
          <CInputCheckbox
            :checked="selected.includes(item.id)"
            add-input-classes="custom-control-input"
            @update:checked="selectOne(item.id, $event)"
          >
            <template #label>
              <label
                class="custom-control-label"
              />
            </template>
          </CInputCheckbox>
        </div>
      </td>
    </template>
     <template #number="{item}">
       <td class="py-2 align-middle">
         {{ item.number }}
       </td>
     </template>
     <template #quarantine="{ item }">
       <td class="py-2 align-middle">
         <div>
           <CBadge color="info">
             {{ item.quarantine ? 'Да' : 'Нет' }}
           </CBadge>
          </div>
         <div>
           <CBadge v-if="item.quarantine" class="mt-2" color="danger">
             {{ item.quarantineText }}
           </CBadge>
        </div>
       </td>
     </template>
    </CDataTable>
    <CPagination
     :activePage="page"
     :pages="totalPages"
     v-on:update:activePage="changePage"
    />
  </CCard>
</template>

<script>
import GroupActions from './group-actions';
import ImportModal from './import-modal';
import {pickBy} from 'lodash';

export default {
  name: 'List',
  components: {
    GroupActions,
    ImportModal
  },
  props: {
    documentType: String
  },
  data() {
    return {
      allCount: 0,
      totalPages: 1,
      page: 1,
      loading: false,
      list: [],
      selected: [],
      availableActions: [],
      isFiltersVisible: false,
      isFiltersSet: false,
      filters: {
        q: '',
        quarantineReason: false,
      }
    }
  },
  computed: {
    listForTable() {
      return this.list.map(el => ({
        ...el,
        '_classes': el.draft ? ['disabled-row'] : []
      }))
    },
    selectedDocuments() {
      return this.list.filter(el => this.selected.includes(el.id));
    },
    allSelected() {
      if (!this.selected.length) {
        return false;
      }
      return this.selected.length === this.list.length;
    },
    fields() {
      return [
        {key: 'groupActions', label: ''},
        {key: 'number', label: 'Номер'},
        {key: 'quarantine', label: 'Карантин'},
        {key: 'amount', label: 'Сумма', _classes: ['font-weight-bold', 'align-middle']},
        {key: 'actions', label: 'Действия', _style: 'text-align: right;'}
      ]
    },
    quarantineSelectValues () {
      return [
        {
          value: null,
          label: 'Все документы'
        },
        {
          value: 'all',
          label: 'Любой карантин'
        },
        {
          value: 'no_access',
          label: 'Нет доступа к МС'
        },
        {
          value: 'no_lines',
          label: 'Не получилось распознать позиции заказа'
        },
        {
          value: 'lines_diff',
          label: 'Позиции заказа отличаются'
        },
        {
          value: 'no_external_store_links',
          label: 'Настроены не все соответствия складов'
        },
        {
          value: 'no_customerorder',
          label: 'Не найден заказ покупателя'
        },
        {
          value: 'demands_diff',
          label: 'Есть созданные отгрузки с неправильным количеством'
        },
        {
          value: 'api_error',
          label: 'Ошибка API'
        }
      ]
    },
    canImportDocuments() {
      return ['Document::InternalOrder'].includes(this.documentType)
    }
  },
  watch: {
    '$route.params': function(val) {
      console.log(val, 'watchParams');
      this.loadDocuments(1);
      this.loadAvailableActions();
    }
  },
  mounted() {
    this.loadDocuments(1);
    this.loadAvailableActions();
  },
  methods: {
    restartSuccess(newDocuments) {
      this.selected = [];
      newDocuments.forEach(newDoc => {
        const index = this.list.findIndex(el => el.id === newDoc.id);
        if (index == -1) return;
        const doc = this.list[index];
        this.list.splice(index, 1, {...doc, ...newDoc})
      })
    },
    selectOne(id, checked) {
      if (checked) {
        this.selected.push(id);
      } else {
        const set = new Set(this.selected);
        set.delete(id);
        this.selected = Array.from(set)
      }
    },
    selectAll(checked) {
      if (checked) {
        this.selected = this.list.map(el => el.id);
      } else {
        this.selected = [];
      }
    },
    async loadAvailableActions() {
      const {data} = await this.$axios.request({url: 'api/documents/available_actions', params: {type: this.documentType}});
      this.availableActions = data;
    },
    async loadDocuments(page = 1) {
      this.loading = true;
      const filters = pickBy({...this.filters, type: this.documentType});
      const {data} = await this.$axios.request({url: 'api/documents', params: {page, ...filters}});
      this.loading = false;
      this.page = page;
      this.list = data.items;
      this.totalPages = data.totalPages;
      this.allCount = data.total;
    },
    changePage(newPage) {
      this.loadDocuments(newPage)
    },
    clearFilters() {
      this.filters = {
        number: '',
        quarantineReason: false,
        documentType: null,
      }
      this.loadDocuments(1);
    }
  }
}
</script>
