import Swal from 'sweetalert2';

export default {
  methods: {
    getFiltersAsQueryString() {
      const queryParams = [];

      const entries = Object.entries(this.filters);

      entries.forEach(([key, value]) => {
        if (value !== null) {
          queryParams.push(`filter[${key}]=${value}`);
        }
      });

      let queryString = '';

      if (queryParams.length > 0) {
        queryString += `?${queryParams.join('&')}`;
      }

      if (this.currentPage > 1) {
        queryString += `${queryParams.length === 0 ? '?' : '&'}page=${this.currentPage}`;
      }

      return queryString;
    },
    isValid(key) {
      if (this.errors[key]) {
        return false;
      }

      return null;
    },
    errorByKey(key) {
      if (this.errors[key]) {
        return this.errors[key].join(', ');
      }
      return null;
    },
    showBaseErrors() {
      const error = this.errorByKey('base');
      if (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: this.errorByKey('base')
        })
      }
    },
    showAllErrors() {
      if (Object.keys(this.errors).length > 0) {
        const errorText = Object.keys(this.errors).map(key => this.errorByKey(key)).join("\n");
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: errorText
        })
      }
    },
    getScalarFieldError(errors, fieldName) {
      return errors[fieldName] && errors[fieldName][0] ? errors[fieldName][0] : null;
    },
    isScalarFieldInvalid(errors, fieldName) {
      return errors[fieldName] && errors[fieldName][0];
    },
    getFormattedCreatedAtDate(createdAt) {
      const date = new Date(createdAt);
      const formatNumber = (number) => (number < 10 ? `0${number}` : number);
      const monthNumber = date.getMonth() + 1;
      const month = formatNumber(monthNumber);
      const hours = formatNumber(date.getHours());
      const minutes = formatNumber(date.getMinutes());
      return `${date.getDate()}:${month}:${date.getFullYear()} ${hours}:${minutes}`;
    },
    hasErrorsInResponse({response}) {
      if (!response)  {
        return false;
      }

      return !!response.data && response.status >= 400 && response.status < 500
    }
  },
};
