<template>
  <CButton
    color="primary"
    type="submit"
    :disabled="loading || disabled"
    @click="$emit('click')"
  >
    <template v-if="loading">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
      <span class="sr-only">{{ loadingText }}</span>
    </template>
    <template v-else>
      {{ text }}
    </template>
  </CButton>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    loading: Boolean,
    disabled: Boolean,
    loadingText: {
      type: String,
      default: 'Подождите...'
    },
    text: {
      type: String,
      default: 'Сохранить'
    }
  }
}
</script>
