<template>
  <CDropdown
    class="m-2"
    color="primary"
    style="display: inline"
    toggler-text="Действия"
  >
    <CDropdownItem
      v-for="action in actions"
      :key="action.handle"
      :disabled="!documents.length || restarts[action.handle]"
      @click="groupProcess(action.handle)"
    >
      <CSpinner
        v-if="restarts[action.handle]"
        class="custom-spinner"
        color="info"
      />
      Запустить {{ action.label }}
      (<span
        v-if="documents.length > 1000"
        class="font-weight-bold"
        style="color: #e55353;"
      >&gt;1000</span>
      <span v-else>{{ documents.length }}</span>)
    </CDropdownItem>
    <CDropdownItem
      v-if="actions.length == 0"
      disabled
      @click="groupProcess(action.handle)"
    >
      Нет доступных сценариев
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  name: 'GroupActions',
  props: {
    documents: Array,
    actions: Array,
  },
  computed: {
    ids() {
      return this.documents.map(el => el.id);
    }
  },
  data() {
    return {
      restarts: {}
    }
  },
  methods: {
    async groupProcess(handle) {
      try {
        this.restarts[handle] = true;
        const {data} = await this.$axios.post('/api/documents/batch_restart', {ids: this.ids, scenario: handle});
        this.$emit('restart:end', data);
        await Swal.fire(
          'Успешно',
          'Документы отправлены на обработку',
          'success',
        );
      } catch(e) {
        await Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Что-то пошло не так'
        })
      } finally {
        this.restarts[handle] = false;
      }
    }
  }
}
</script>
